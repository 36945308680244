<template>
  <v-container fluid>
    <data-table modelName="actor"> </data-table>
  </v-container>
</template>

<script>
import DataTable from "@/components/DataTable";

export default {
  components: {
    DataTable
  }
};
</script>

<style lang="scss" scoped></style>
